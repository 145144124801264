import { initiateMembershipPayment } from "@App/api/memberships";
import { MEMBERSHIP_DOGS } from "@App/constants/appConstants";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_USER_RESERVATION_LIST } from "@App/constants/queryKeyConstants";

const MembershipPaymentLogic = () => {
  const [membershipTitle, setMembershipTitle] = useState(String);
  const [parkName, setParkName] = useState(String);
  const [files, setFiles] = useState<any[]>([]);
  const { userMembershipId, rateId } = useParams<{
    userMembershipId: string;
    rateId: string;
  }>();
  const [membershipPurchaseData, setMembershipPurchaseData] =
    useState<any>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (userMembershipId && rateId) {
      try {
        initiateMembershipPayment({
          membershipRequests: [{ userMembershipId, rateId }],
        }).then((res) => {
          setMembershipPurchaseData(res);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [rateId, userMembershipId]);

  useEffect(() => {
    //This can be dogs or anything else. We need to add the other scenarios we need.
    const membershipDetail = localStorage.getItem(MEMBERSHIP_DOGS);
    if (membershipDetail) {
      const membershipData = JSON.parse(membershipDetail);
      setMembershipTitle(membershipData.title);
      setParkName(membershipData.preferredPark);

      setFiles(membershipData.files);
    }
  }, []);

  const onPaymentSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEY_USER_RESERVATION_LIST]);
  };

  return {
    membershipTitle,
    parkName,
    membershipPurchaseData,
    files,
    onPaymentSuccess,
  };
};

export default MembershipPaymentLogic;
